<script setup lang="ts">
// vuetify
import { useDisplay, useTheme } from 'vuetify'

// types
import type { Nullable } from '@revolutionprep/types'

// stores
import { useGlobalStore } from '@/store/global'

/**
 * runtime config
 * ==================================================================
 */
const config = useRuntimeConfig()

/**
 * nuxt app
 * ==================================================================
 */
const { $actor } = useNuxtApp()

/**
 * vuetify
 * ==================================================================
 */
const { lgAndUp } = useDisplay()
const vuetifyTheme = useTheme()

/**
 * stores
 * ==================================================================
 */
const globalStore = useGlobalStore()
const { isLoading } = storeToRefs(globalStore)

const organizationStore = useOrganizationStore()
const { organizationInfo: company } = storeToRefs(organizationStore)

/**
 * composables
 * ==================================================================
 */
const { isMobile } = useDevice()
const { doHandleError } = useErrorHandler()
const { menuItems } = useMenuItems()

/**
 * state
 * ==================================================================
 */
const capturedError = ref<Nullable<Error>>(null)
const drawer = ref(lgAndUp)
const renderError = ref(false)

// computed
const actor = computed(() => {
  return $actor.core.actor.value
})

const isLoggedIn = computed(() => {
  return Boolean($actor.core.isLoggedIn.value && actor.value)
})

const mainStyles = computed(() => {
  return {
    background: vuetifyTheme.current.value.colors.backgroundgrey,
    height: '100%'
  }
})

// methods
function doToggleDrawer () {
  drawer.value = !drawer.value
}

/**
 * lifecycle hooks
 * ==================================================================
 */
onBeforeMount(() => {
  isLoading.value = true
})

onMounted(() => {
  isLoading.value = false
})

onErrorCaptured((
  err: Error,
  _instance: ComponentPublicInstance | null,
  info: string
) => {
  doHandleError(err)
  capturedError.value = err
  renderError.value = info === 'render'
  return false
})
</script>

<template>
  <v-app>
    <div
      v-if="isLoading"
      class="d-flex"
      style="height: 100%;"
    >
      <div
        v-if="!isMobile"
        class="d-flex flex-column"
        style="width: 256px; border-right: 0.75px solid #E4E4E4;"
      >
        <v-skeleton-loader
          v-for="i in 6"
          :key="`loader-${i}`"
          type="list-item"
          width="256px"
          height="56px"
          style="border-right: 0.75px solid #E4E4E4; border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
        />
      </div>
      <div
        class="d-flex flex-column"
        style="width: 100%;"
      >
        <v-skeleton-loader
          v-if="isLoading"
          type="list-item"
          width="100%"
          height="56px"
          style="border-bottom: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
        />
        <v-skeleton-loader
          v-if="isLoading"
          width="100%"
          height="calc(100vh - 126px)"
          style="border-radius: 0px;"
        />
        <v-skeleton-loader
          v-if="isLoading"
          type="list-item"
          width="100%"
          height="70px"
          style="border-top: 0.75px solid #E4E4E4; border-radius: 0px; z-index: 6;"
        />
      </div>
    </div>
    <div
      v-else
      style="height: 100%;"
    >
      <lazy-r-navigation-drawer
        :actor="actor"
        :app-stage="config.public.appStage"
        :app-version="config.public.appVersion"
        :drawer="drawer"
        :menu-items="menuItems"
        :settings="config"
        @toggle-drawer="doToggleDrawer"
      />
      <client-only>
        <LazyAppBar @toggle-drawer="doToggleDrawer" />
      </client-only>
      <v-main
        :style="mainStyles"
        class="mb-4"
      >
        <v-container class="container mx-auto pt-8">
          <slot v-if="!renderError" />
          <r-error-display
            v-else-if="renderError && capturedError"
            :error="capturedError"
          />
        </v-container>
      </v-main>
      <client-only>
        <r-footer
          :app="isLoggedIn"
          :company="company"
          :logged-in="isLoggedIn"
          :settings="config"
        />
      </client-only>
    </div>
  </v-app>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  transform: translateY(10px);
  opacity: 0;
}

.container {
  height: 100%;
}

@media (min-width: 960px) {
  .container {
    width: 75vw;
  }
}

@media (min-width: 600px) and (max-width: 960px) {
  .container {
    width: 80vw;
  }
}

@media (max-width: 600px) {
  .container {
    width: 95vw;
  }
}
</style>
